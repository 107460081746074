var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-app":""}},[_c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Dashboard ")])])])]}}])}),_c('router-link',{attrs:{"to":"/orders"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Order List ")])])])]}}])}),_c('router-link',{attrs:{"to":"/max"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('span',{staticClass:"menu-text"},[_vm._v(" KDS ")])])])]}}])}),_c('router-link',{attrs:{"to":"/pos"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('span',{staticClass:"menu-text"},[_vm._v(" POS ")])])])]}}])}),_c('router-link',{attrs:{"to":"/driverdispatch"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Driver Dispatch ")])])])]}}])}),_c('router-link',{attrs:{"to":"/timetable"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Time Clock ")])])])]}}])}),_c('router-link',{attrs:{"to":"/banks"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Banks ")])])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }